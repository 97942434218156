import ApplicationController from "./application_controller";

export default class extends ApplicationController {
  static targets = ["header", "content", "navigationDot", "backButton", "nextButton"];
  static values = { currentIndex: Number, visitedSlides: Array };

  connect() {
    this.currentIndexValue = 0;
    this.visitedSlidesValue = [];
    this.updateUI();
  }

  navigate(event) {
    const targetIndex = parseInt(event.currentTarget.dataset.index, 10);
    if (targetIndex === 0 && this.currentIndexValue !== 0) {
      this.visitedSlidesValue = [];
    }
    this.markVisited(this.currentIndexValue);
    this.changeSlide(targetIndex);
  }

  next() {
    if (this.currentIndexValue < this.navigationDotTargets.length - 1) {
      this.markVisited(this.currentIndexValue);
      this.changeSlide(this.currentIndexValue + 1);
    }
  }

  back() {
    if (this.currentIndexValue > 0) {
      this.unmarkVisited(this.currentIndexValue);
      this.changeSlide(this.currentIndexValue - 1);
    }
  }

  changeSlide(newIndex) {
    this.currentIndexValue = newIndex;
    this.updateUI();
  }

  markVisited(index) {
    if (!this.visitedSlidesValue.includes(index)) {
      this.visitedSlidesValue = [...this.visitedSlidesValue, index];
    }
  }

  unmarkVisited(index) {
    this.visitedSlidesValue = this.visitedSlidesValue.filter((i) => i !== index);
  }

  updateUI() {
    const headerData = this.headerTarget.getAttribute(`data-feature-discovery-slider-header-${this.currentIndexValue}-value`);
    this.headerTarget.innerHTML = headerData;
    const contentData = this.contentTarget.getAttribute(`data-feature-discovery-slider-content-${this.currentIndexValue}-value`);
    this.contentTarget.innerHTML = contentData;
    this.navigationDotTargets.forEach((dot, index) => {
      dot.classList.remove("active-slide", "visited-slide");

      if (index === this.currentIndexValue) {
        dot.classList.add("active-slide");
      } else if (this.visitedSlidesValue.includes(index)) {
        dot.classList.add("visited-slide");
      }
    });

    this.backButtonTarget.disabled = this.currentIndexValue === 0;
    this.nextButtonTarget.innerHTML = this.currentIndexValue === this.navigationDotTargets.length - 1 ? 'Close' : 'Next';
    this.nextButtonTarget.classList.toggle(
      'final-step',
      this.currentIndexValue === this.navigationDotTargets.length - 1
    );
    this.nextButtonTarget.dataset.action = this.currentIndexValue === this.navigationDotTargets.length - 1 ? "click->modal--modal#closeModal" : "click->feature-discovery-slider#next"
  }
}